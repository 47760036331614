import Typography from "@mui/material/Typography";


const Contact = () => {

return(
	<Typography id="navRightBarMenu">
		Contact
	</Typography>
)
}
export default Contact;
